import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';

//------- page components -------
import { NoHasPlansGuard } from './guards/no-has-plans.guard';
import { HasPlansGuard } from './guards/has-plans.guard';
import { PharmaHolderNoGroupGuard } from './guards/pharmaHolderNoGroup.guard';
import { CanConfigureItGuard } from './guards/canConfigureIt.guard';
import { FromCartGuard } from './guards/fromCart/fromCart.guard';
//------- -------

const routes: Routes = [
  
  {
    path: 'cursos',
    loadChildren: () => import('./components/pages/courses/courses.module').then(m => m.CoursesModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'cursos/:id',
    loadChildren: () => import('./components/pages/course-detail/course-detail.module').then(m => m.CourseDetailModule),
    // resolve: {data: NoHasPlansGuard}
  },
  // {
  //   path: 'auth/:tk',
  //   loadChildren: () => import('./components/pages/auto-login/auto-login.module').then(m => m.AutoLoginModule),
  // },
  // {
  //   path: 'aula/campus',
  //   loadChildren: () => import('./components/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
  //   resolve: {data: HasPlansGuard}
  // },
  // {
  //   path: 'aula/mis-itinerarios',
  //   loadChildren: () => import('./components/pages/my-itineraries/my-itineraries.module').then(m => m.MyItinerariesModule),
  //   resolve: {data: HasPlansGuard,
  //             student: CanConfigureItGuard}
  // },
  // {
  //   path: "aula/mis-itinerarios/configurar-itinerario",
  //   loadChildren: () => import('./components/pages/itinerary-configurator/itinerary-configurator.module').then(m => m.ItineraryConfiguratorModule),
  //   resolve: {data: HasPlansGuard,
  //     student: CanConfigureItGuard}
  //     // student: PharmaHolderNoGroupGuard}
  // },
  // {
  //   path: 'certificados/:id',
  //   loadChildren: () => import('./components/pages/certificate/certificate.module').then(m => m.CertificateModule),
  // },
  // {
  //   path: 'aula/mis-suscripciones',
  //   loadChildren: () => import('./components/pages/my-subscriptions/my-subscriptions.module').then(m => m.MySubscriptionsModule),
  //   resolve: {data: HasPlansGuard}
  // },
  // {
  //   path: 'aula/liceo-play',
  //   loadChildren: () => import('./components/pages/liceo-play/liceo-play.module').then(m => m.LiceoPlayModule),
  //   resolve: {data: HasPlansGuard}
  // },
  // {
  //   path: 'aula/la-infiltrada',
  //   loadChildren: () => import('./components/pages/la-infiltrada/la-infiltrada.module').then(m => m.LaInfiltradaModule),
  //   resolve: {data: HasPlansGuard}
  // },
  // {
  //   path: 'aula/protocolos-de-consejo',
  //   loadChildren: () => import('./components/pages/protocolos-de-consejo/protocolos-de-consejo.module').then(m => m.ProtocolosDeConsejoModule),
  //   resolve: {data: HasPlansGuard}
  // },
  {
    path: 'planes/:id',
    loadChildren: () => import('./components/pages/plan-detail/plan-detail.module').then(m => m.PlanDetailModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'metodo-liceo',
    loadChildren: () => import('./components/pages/liceo-method/liceo-method.module').then(m => m.LiceoMethodModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'planes',
    loadChildren: () => import('./components/pages/plans/plans.module').then(m => m.PlansModule),
    // resolve: {data: NoHasPlansGuard}
  },
  //nuevas url nuevo flujo de carrito
  {
    path: 'prueba-gratis/seleccion',
    loadChildren: () => import('./components/pages/free-trial-selection/free-trial-selection.module').then(m => m.FreeTrialSelectionModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'compra/seleccion',
    loadChildren: () => import('./components/pages/free-trial-selection/free-trial-selection.module').then(m => m.FreeTrialSelectionModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'prueba-gratis/contacto',
    loadChildren: () => import('./components/pages/titular-contact/titular-contact.module').then(m => m.TitularContactModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'compra/contacto',
    loadChildren: () => import('./components/pages/titular-contact/titular-contact.module').then(m => m.TitularContactModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'contacto',
    loadChildren: () => import('./components/pages/titular-contact/titular-contact.module').then(m => m.TitularContactModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'prueba-gratis/fin-contacto',
    loadChildren: () => import('./components/pages/contact-success/contact-success.module').then(m => m.ContactSuccessModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'compra/fin-contacto',
    loadChildren: () => import('./components/pages/contact-success/contact-success.module').then(m => m.ContactSuccessModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'fin-contacto',
    loadChildren: () => import('./components/pages/contact-success/contact-success.module').then(m => m.ContactSuccessModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'compra',
    loadChildren: () => import('./components/pages/purchase/purchase.module').then(m => m.PurchaseModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'compra/error',
    loadChildren: () => import('./components/pages/purchase-error/purchase-error.module').then(m => m.PurchaseErrorModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'prueba-gratis/pago/error',
    loadChildren: () => import('./components/pages/purchase-error/purchase-error.module').then(m => m.PurchaseErrorModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'prueba-gratis/:id',
    loadChildren: () => import('./components/pages/free-trial-purchase/free-trial-purchase.module').then(m => m.FreeTrialPurchaseModule),
    // resolve: {data: NoHasPlansGuard}
  },
  //fin nuevas url nuevo flujo de carrito
  {
    path: 'gracias-por-tu-compra',// compra normal
    loadChildren: () => import('./components/pages/confirm-purchase/confirm-purchase.module').then(m => m.ConfirmPurchaseModule),
  },
  {
    path: 'gracias-por-suscribirte',//prueba gratis
    loadChildren: () => import('./components/pages/confirm-purchase/confirm-purchase.module').then(m => m.ConfirmPurchaseModule),
  },
  {
    path: 'ya-tienes-un-plan-activo',
    loadChildren: () => import('./components/pages/already-have-active-plan/already-have-active-plan.module').then(m => m.AlreadyHaveActivePlanModule),
  },
  {
    path: 'prueba-gratis/ya-disfrutada',
    loadChildren: () => import('./components/pages/demo-already-used/demo-already-used.module').then(m => m.DemoAlreadyUsedModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'compra-sin-finalizar',
    loadChildren: () => import('./components/pages/purchase-ko/purchase-ko.module').then(m => m.PurchaseKoModule),
  },
  {
    path: 'mi-perfil/iniciar-sesion',
    loadChildren: () => import('./components/pages/profile-login/profile-login.module').then(m => m.ProfileLoginModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'mi-perfil',
    loadChildren: () => import('./components/pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'aviso-legal',
    loadChildren: () => import('./components/pages/legal-advise/legal-advise.module').then(m => m.LegalAdviseModule),
  },
  {
    path: 'descargar-app/:id',
    loadChildren: () => import('./components/pages/redirection-landing/redirection-landing.module').then(m => m.RedirectionlandingModule),
  },
  {
    path: 'politica-de-privacidad',
    loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'politica-de-cookies',
    loadChildren: () => import('./components/pages/cookies-policy/cookies-policy.module').then(m => m.CookiesPolicyModule),
  },
  {
    path: '',
    loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'que-es-liceo',
    loadChildren: () => import('./promo/pages/commercial/commercial.module').then(m => m.Commercialodule),
    data:{isComercial: true}
  },
  {
    path: 'que-es-liceo/:sponsor',
    loadChildren: () => import('./promo/pages/commercial/commercial.module').then(m => m.Commercialodule),
    data:{isComercial: true}
  },
  {
    path: 'eventos/infarma',
    loadChildren: () => import('./components/pages/infarma/infarma.module').then(m => m.InfarmaModule),
    // resolve: {data: NoHasPlansGuard}
  },
  {
    path: 'descargar/protocolos',
    loadChildren: () => import('./components/pages/download/download.module').then(m => m.DownloadModule),
  },
  {
    path: 'descargar/infiltrada',
    loadChildren: () => import('./components/pages/download/download.module').then(m => m.DownloadModule),
  },
  {
    path: 'descargar/liceoplay',
    loadChildren: () => import('./components/pages/download/download.module').then(m => m.DownloadModule),
  },
  {
    path: 'preguntas-frecuentes',
    loadChildren: () => import('./components/pages/faqs/faqs.module').then(m => m.FaqsModule),
  },
  {
    path: 'salud_capilar',
    loadChildren: () => import('./components/pages/landing-course-expert/landing-course-expert.module').then(m => m.LandingCourseExpertModule),
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full'
  }
];

const routerOptions: ExtraOptions = {
  enableTracing: false, // Habilita la depuración del enrutamiento
  // Aquí puedes agregar otras opciones de configuración
};

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
